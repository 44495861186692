<template>
  <div class="min-h-screen flex justify-center items-center text-center mx-auto">
    <div>
      <img alt="ShiftPixy Logo" src="./assets/logo.png" class="inline" width="100">
      <p class="py-5">You should be redirected shortly...</p>
      <p class="py-16">Not working? <a class="hover:underline text-blue-600" href="https://onboarding.shiftpixy.com">Click Here to be redirected</a></p>
    </div>
  </div>
</template>

<script>
const ios = 'iPhone'
const user = navigator.platform;
const android = user.includes('Linux')
if (user == ios) {
  window.location.replace('https://apps.apple.com/us/app/shiftpixy-meaningful-gig-work/id1226351402');
} else if(android === true) {
  window.location.replace('https://play.google.com/store/apps/details?id=com.shiftpixy.android')
} else {
  window.location.replace('https://onboarding.shiftpixy.com')
}
</script>